import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import GalleryImg9 from "../../assests/images/gallery9.jpg";
import GalleryImg10 from "../../assests/images/gallery10.jpg";
import GalleryImg11 from "../../assests/images/gallery11.jpg";
import GalleryImg12 from "../../assests/images/gallery12.jpg";
import GalleryImg13 from "../../assests/images/gallery13.jpg";

import {config} from '../../components/Common/constant';

import FsLightbox from 'fslightbox-react';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, GET_PHOTOGRAPHER_PROPERTY } from "../../queries/common_use_query";

import Title_Block from  "../modules/title_block"; 

import SampleVideos from  "./work-sample-videos";
import SamplePhotos from  "./work-sample-photos"; 

const WorldClassPhoto = (props) => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('world-class-photography');

    const { loading:photographer_loading, error:photographer_error, data:photographer_data } = GET_PHOTOGRAPHER_PROPERTY(props.property_data && props.property_data.photographer1 && props.property_data.photographer1.Email);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 0
    });

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }


    var tourImages = [{'src_url': '', 'title': 'Photography', 'type': 'image'}, {'src_url': '', 'title': '360 Degree Tour', 'video_url': '', 'type': 'video'}, {'src_url': '', 'title': 'Photography', 'type': 'image'}];

    var lightImages2 = tourImages && tourImages.map(img => img.type === 'image' ? img.src_url : img.video_url);

    var inc = 0;

    //console.log("property_data", props.property_data.photographer1.Email)
    //console.log("photographer_data", photographer_data)
    var photographer = photographer_data && photographer_data.photographers && photographer_data.photographers.length > 0 ? photographer_data.photographers[0] : [];
    
    return <>

        <div className="section-wrapper">
          <Container>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                slide={lightboxController.slide}
            />

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={config.custom_marketing_analytics} NextLink={config.custom_digital_domination} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={true} />

            <p className={'fig-content mb_cnt font_20'}>{article_data && HTMLReactParser(article_data.Content)}</p>
            
            {/* <div className="gallery-wrapper">
              <div className="gallery-row">
                
                {
                    tourImages.map((item, index) => {

                        inc++;
                        if(inc === 6) {
                            inc = 1;
                        }
                        return(
                            <div className={`gallery-img img${inc} ${item.type === "video" ? 'video-wrap' : ''}`}>
                                {
                                    item.src_url ? (
                                        <figure >
                                            <img src={item.src_url} alt="gallery-img" onClick={(e) => openLighboxMobile(e, index + 1)} />
                                        </figure>
                                    ) : (
                                        
                                        <span className="coming_soon">{item.type === "video" ? 'Video Coming Soon' : 'Image Coming Soon' }</span>
                                    )
                                }
                                
                                {
                                    item.type === "video" && item.video_url && (
                                        <a href="javascript:;" onClick={(e) => openLighboxMobile(e, index + 1)}>
                                            <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                                                </g>
                                                <defs>
                                                <clipPath>
                                                <rect width="22" height="25.2703" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    )
                                }
                            </div>
                        )
                        
                    })
                }
            </div>
            
            </div>
             */}
            {/* gallery-wrapper */}

            <div className="sample_videos">
                <div className="example-wrapper">
                    <SamplePhotos PhotographerPhotos={photographer && photographer.samplePhoto && photographer.samplePhoto.length > 0 ? photographer.samplePhoto : []} />

                    <SampleVideos PhotographerVideos={photographer && photographer.tourSample360 && photographer.tourSample360.length > 0 ? photographer.tourSample360 : []} />
                </div>
            </div>
            
          </Container>
      </div>
      {/* section-wrapper */}

    </>
}

export default WorldClassPhoto