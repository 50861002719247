import React,{useState} from "react"
import {Container,Row,Col,Navbar,Nav, Button} from 'react-bootstrap';
import { Link } from "gatsby"
import ItemsCarousel from 'react-items-carousel';
import FsLightbox from 'fslightbox-react';
import VideoImg6 from "../../assests/images/video6.jpg";
import VideoImg7 from "../../assests/images/video7.jpg";
import rightArw from "../../assests/images/right-arrow.svg";
import leftArw from "../../assests/images/left-arrow.svg";

import VideoModule from "./video-module"

function SampleVideos(props){
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }


    var SlideImages = [];

    props.PhotographerVideos && props.PhotographerVideos.length > 0 && props.PhotographerVideos.map((item, index)=>{
      SlideImages.push({'src_url': '', 'title': '360 Degree Tour', 'video_url': item, 'type': 'video'});
  })

    var lightImages2 = SlideImages && SlideImages.map(img => img.type === 'image' ? img.src_url : img.video_url);

    //console.log('lightboxController', lightboxController);
    //console.log('SlideImages', SlideImages);
    
    return (
    <>
      <FsLightbox
          toggler={lightboxController.toggler}
          sources={lightImages2}
          slide={lightboxController.slide}
      />

      <div className={'sliderCarousel photographer mr-0'}>
        <ItemsCarousel
          infiniteLoop={true}
          gutter={12}
          activePosition={'center'}
          chevronWidth={60}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={1}
          slidesToScroll={1}
          outsideChevron={true}
          showSlither={false}
          firstAndLastGutter={false}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={value => setActiveItemIndex(value)}
          rightChevron={<Button shape="circle" variant="default"><img src={rightArw} alt="Next" title="" /></Button>}
          leftChevron={<Button shape="circle" variant="default"><img src={leftArw} alt="Prev" title="" /></Button>}
        >
          {
            SlideImages.map((item, index) => {
              return(
              <div key={1} className="carousalItem">
                <div className="customer-block">

                    <div className="customer-card">
                      <div className="video-wrap">
                        {
                            item.video_url ? (
                            // <figure>
                            //     <img src={item.src_url} alt="video-img" />
                            // </figure>
                            // <VideoModule video_url={item.video_url} playing={false} loop={true} width={'100%'} height={'100%'} /> 
                            <iframe src={item.video_url} width={"100%"} height={"100%"} frameBorder={0} allowFullScreen={true}></iframe>
                            ) : (<span className="coming_soon">Video Coming Soon</span>)
                        }
                        {/* {
                          item.video_url && (
                            <a href="javascript:;">
                              <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0)">
                              <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                              </g>
                              <defs>
                              <clipPath>
                              <rect width="22" height="25.2703" fill="white"/>
                              </clipPath>
                              </defs>
                              </svg>
                            </a>
                          )
                        } */}
                        
                      </div>
                    {/* video-wrap */}
                    
                    </div>

                  {/* customer-card */}
                </div>
              </div>
              )
            })
          }
        </ItemsCarousel>

        <div className="customer-name">
          <h3>Immersive 360 Tour</h3>
        </div>
        {/* customer-name */}
      </div>
    </>
    )
}

export default SampleVideos
