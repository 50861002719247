import React,{useState} from "react"
import {Container,Row,Col,Navbar,Nav, Button} from 'react-bootstrap';
import { Link } from "gatsby"
import ItemsCarousel from 'react-items-carousel';
import FsLightbox from 'fslightbox-react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import VideoImg6 from "../../assests/images/video6.jpg";
import VideoImg7 from "../../assests/images/video7.jpg";
import rightArw from "../../assests/images/right-arrow.svg";
import leftArw from "../../assests/images/left-arrow.svg";

function SamplePhotos(props){
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });

    // const openLighboxMobile = (e, ind) => {
    //     e.preventDefault();
    //     setLightboxController({
    //         toggler: !lightboxController.toggler,
    //         slide: ind
    //     });
    // }

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }


    var SlideImages = [];

    props.PhotographerPhotos && props.PhotographerPhotos.length > 0 && props.PhotographerPhotos.map((item, index)=>{
        SlideImages.push({'src_url': item, 'title': 'Photography', 'type': 'image'});
    })

    var lightImages2 = SlideImages && SlideImages.map(img => img.src_url);

    //console.log('lightboxController', lightboxController);
    //console.log('lightImages2', lightImages2, photoIndex);
    
    return (
    <>

        {/* <FsLightbox
            toggler={lightboxController.toggler}
            sources={lightImages2}
            slide={lightboxController.slide}
        /> */}

        {isOpen && (
          <Lightbox
            mainSrc={lightImages2[photoIndex]}
            nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
            prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % lightImages2.length)
            }
          />
        )}

        <div className={'sliderCarousel photographer'}>
            <ItemsCarousel
            infiniteLoop={true}
            gutter={12}
            activePosition={'center'}
            chevronWidth={60}
            disableSwipe={false}
            alwaysShowChevrons={false}
            numberOfCards={1}
            slidesToScroll={1}
            outsideChevron={true}
            showSlither={false}
            firstAndLastGutter={false}
            activeItemIndex={activeItemIndex}
            requestToChangeActive={value => setActiveItemIndex(value)}
            rightChevron={<Button shape="circle" variant="default"><img src={rightArw} alt="Next" title="" /></Button>}
            leftChevron={<Button shape="circle" variant="default"><img src={leftArw} alt="Prev" title="" /></Button>}
            >
            {
                SlideImages.map((item, index) => {

                return(
                    <div key={index} className="carousalItem">
                    <div className="customer-block">
                        <a href="javascript:;"  onClick={(e) => openLighboxMobile(e, index)}>
                        <div className="customer-card">
                            <div className="video-wrap">
                            {
                               item.src_url ? (
                                <figure>
                                    <img src={item.src_url} alt="video-img" />
                                </figure>
                               ) : (<span className="coming_soon">Image Coming Soon</span>)
                            }
                            
                            </div>
                        {/* video-wrap */}                        
                        </div>
                        </a>
                        {/* customer-card */}
                    </div>
                    </div>
                    ) 
                
                })
            }
            </ItemsCarousel>

            <div className="customer-name">
                <h3>Professional Photography</h3>
            </div>
            {/* customer-name */}
        </div>
      
    </>
    )
}

export default SamplePhotos
